const info={
  ZH: {  //中文翻译
    placeholder: '请输入关键词',
    navName: ['首页', '关于我们', 'AAV产品', '商务合作', '联系我们', '品牌动态']
  },
  EN: {  //英文翻译
    placeholder: 'please enter keywords',
    navName: ['home', 'about us', 'AAV product', 'cooperation', 'contact', 'brand']
  }
}
export default info