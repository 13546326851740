<template>
  <div class="about">
    <head-area-en :activeStr="'home'" class="about_head_area" :class="[!isMobile && 'opacity']" @unfold="flag => isUnFold = flag"></head-area-en>
    <!--轮播-->
    <video-carousel v-if="!isUnFold" :carouselList="carouselList"></video-carousel>
    <!-- <Carousel :carouselList="carouselList"></Carousel> -->
    <!-- <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide sl-box" v-for="(item, index) in carouselList" :key="index">
          <img class="lb-img" :src="item" alt="">
        </div>
      </div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div> -->
    <!-- <div class="pbanner">
        <video src="../../assets/videos/AAV-brand.mp4" loop autoplay muted></video>
    </div> -->
    <!-- <video-carousel :carouselList="carouselList"></video-carousel> -->
    <div class="about-main">
        <div class="product-item wow animate__animated animate__slideInLeft">
            <div class="product-info-text wow animate__animated animate__slideInUp">
                <div class="product-info-text-title">BRAND INTRODUCTION</div>
                <div class="product-info-text-manifesto">
                    <span>LOVE</span>
                    <span>EQUAL</span>
                    <span>FREE</span>
                    <span>INTERESTING</span>
                </div>
                <p>AAV is a new brand in the sex industry.</p>
                <p>To "explore wonderful fun, create excellent quality" as the development concept.</p>
                <p>Adhere to the user value as the center, in order to create both interesting</p>
                <p>and not follow the flow of products as the original intention,</p>
                <p>continue to explore the emotional connection between users and sex life,</p>
                <p>to provide users with free, equal and interesting sex life experience.</p>
            </div>
            <div class="product-item-bgc intro"></div>
            <!-- <img src="../../assets/images/about/product-notext1.jpg" alt=""> -->
        </div>
        <div class="brand-main">
            <div class="brand-main-title">BRAND CULTURE</div>
            <updown-carousel :carouselList="branCultureList"></updown-carousel>
        </div>
        <div class="product-item wow animate__animated animate__slideInRight">
            <div class="product-info-text wow animate__animated animate__slideInUp">
                <div class="product-info-text-title">BRAND DEVELOPMENT</div>
                <div class="product-info-text-manifesto">
                    <span>LOVE</span>
                    <span>EQUAL</span>
                    <span>FREE</span>
                    <span>INTERESTING</span>
                </div>
                <p>Brand products have entered North America, Europe, South America,</p>
                <p>Asia and other overseas markets, serving users around the world.</p>
                <p>After continuous efforts and development,</p>
                <p>AAV has now become a rising star of global sex toy brands.</p>
            </div>
            <div class="product-item-bgc develop"></div>
            <!-- <img src="../../assets/images/about/product-notext2.jpg" alt=""> -->
        </div>
        <div class="brand-main">
            <div class="brand-main-title">BRAND STORY</div>
            <updown-carousel :carouselList="branIdeaList"></updown-carousel>
        </div>
        <div class="product-item wow animate__animated animate__slideInLeft">
            <div class="product-info-text wow animate__animated animate__slideInUp">
                <div class="product-info-text-title">BRAND VISION</div>
                <div class="product-info-text-manifesto special">
                    <span>Become the global sex toys industry leader</span>
                </div>
            </div>
            <div class="product-item-bgc story"></div>
            <!-- <img src="../../assets/images/about/product-notext3.jpg" alt=""> -->
        </div>
    </div>
    <footer-area-en></footer-area-en>
  </div>
</template>

<script>
import videoCarousel from '@/components/carousel/video-carousel.vue'
// import Carousel from '@/components/carousel/Carousel.vue'
import updownCarousel from '@/components/carousel/updown-carousel.vue'

export default {
    name: 'homeViewEn',
    components: { videoCarousel, updownCarousel },
    data () {
        return {
            // carouselList: [require('@/assets/videos/product-video.mp4')],
            carouselList: [require('@/assets/videos/productVideo.mp4')],
            branCultureList: [
                {
                    src: require('@/assets/images/about/carousel1.jpg'),
                    title: 'BRAND SLOGAN',
                    info: 'AAV make love more fun'
                },
                {
                    src: require('@/assets/images/about/carousel2.jpg'),
                    title: 'BRAND CONCEPT',
                    info: 'Discover fantastic fun and create exceptional quality'
                },
                {
                    src: require('@/assets/images/about/carousel3.jpg'),
                    title: 'BRAND MISSION',
                    info: "To provide users with free, equal, fun love"
                }
            ],
            branIdeaList: [
                {
                    src: require('@/assets/images/about/carousel4.jpg'),
                    title: 'ORIGIN',
                    info: 'Breaking the traditional shackles, born for "love", and committed to providing users with free, equal and interesting "love" is the starting point of the birth of AAV'
                },
                {
                    src: require('@/assets/images/about/carousel5.jpg'),
                    title: 'NOW',
                    info: 'Take user value as the center, refuse to follow blindly, dare to be different, combine love and fun, and unlock more happy ways'
                },
                {
                    src: require('@/assets/images/about/carousel6.jpg'),
                    title: 'FUTURE',
                    info: 'Adhering to the concept of "exploring wonderful fun, creating excellent quality"'
                }
            ],
            active: false,
            isMobile: false,
            isUnFold: false
        }
    },
    mounted() {
        this.resize()
        this.$nextTick(() => {
            this.$wow.init()
            setTimeout(() => {
                const top = document.documentElement.scrollTop
                document.documentElement.scrollTop = top ? 0 : 1
            }, 100)
            // this.$wow.start()
        })
        // this.scrollFun()
		window.addEventListener('resize', this.resize)
    },
	beforeDestroy () {
		window.removeEventListener('resize', this.resize)
	},
    methods: {
		resize () {
            // 是否为移动端
            const width = document.documentElement.clientWidth || document.body.clientWidth
			if (width > 800) {
				this.isMobile = false
			} else {
				this.isMobile = true
			}
		},
		// scrollFun () {
		// 	const top = document.documentElement.scrollTop
		// 	if (top >= window.innerHeight - 80) {
		// 		this.active = false
		// 	} else {
		// 		this.active = true
		// 	}
		// }
    }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
.about {
    ::v-deep .swiper .sl-text strong {
        font-size: 20px;
    }
    ::v-deep .swiper .sl-text p {
        font-size: 14px;
    }

    .about-main .product-item {
        .product-info-text {
            font-size: 0.73vw;
        }

        .product-info-text-manifesto {
            font-size: 2.1vw;
            margin: 1.6vw 0 3.2vw;
        }
    }
}
@import './index-mobile.scss';
</style>