<template>
	<!--头部-->
	<header class="header">
		<div class="header_bg"></div>
		<div class="header-area center_box_linl">
			<div class="header-bar-left">
				<div class="brand_text" @click="toHome">
					<img class="brand_img" src="../../assets/images/brand-en.png" alt="">
				</div>
			</div>
			<div class="header-bar-center">
				<div class="navbar" v-if="!isMobile">
					<div class="nav-item" @click="tabClick(0)"><span :class="{ active : activeStr == 'home'}" class="nav-link" text="HOME">HOME</span></div>
					<div class="nav-item" @click="tabClick(1)"><span :class="{ active : activeStr == 'about'}" class="nav-link" text="ABOUT US">ABOUT US</span></div>
					<div class="nav-item" @click="tabClick(2)"><span :class="{ active : activeStr == 'product'}" class="nav-link" text="PRODUCT">PRODUCT</span></div>
					<!-- <div class="nav-item" @click="tabClick(3)"><span :class="{ active : activeStr == 'cooperate'}" class="nav-link" text="COOPERATE">COOPERATE</span></div> -->
					<div class="nav-item" @click="tabClick(4)"><span :class="{ active : activeStr == 'contact'}" class="nav-link" text="CONTACT">CONTACT</span></div>
					<!-- <div class="nav-item" @click="tabClick(5)"><span :class="{ active : activeStr == 'news'}" class="nav-link" text="BRAND NEWS">BRAND NEWS</span></div> -->
				</div>
			</div>
			<div class="header-bar-right">
				<div class="header-bar-right-one" v-if="!isMobile">
					<div class="header-bar-search">
						<!-- <div class="line"></div> -->
						<div class="search-main">
							<div class="inputBox" :class="inputActive && 'inputActive'">
								<input class="input" :value="inputVal" ref="input" type="text" :placeholder="$t('header.placeholder')">
								<i class="closeIcon" @click="close">×</i>
							</div>
							<div class="search-btn" :class="inputActive && 'inputActive'" @click="search"></div>
						</div>
					</div>
					<div class="language_change_box">
						<i class="language-icon"></i>
						<!-- <div class="language_item active">简体中文</div>
						<div class="language_item" @click="changeLanguage('en')">English</div> -->
						<ul class="language_list">
							<li class="language_li" @click="changeLanguage('cn')">简体中文</li>
							<li class="language_li active">English</li>
						</ul>
					</div>
				</div>
				<div v-else class="menuBtn" :class="[ isUnFold && 'active' ]" @click="toggleUnFold">
					<span class="menuIcon"></span>
				</div>
			</div>
		</div>
		<div class="mobile-menu" :class="[ isUnFold && 'active' ]">
			<ul class="mobile-menu-ul">
				<li class="mobile-menu-li" @click="tabClick(0)"><div class="mobile-menu-title" :class="{ active : activeStr == 'home'}">HOME</div></li>
				<li class="mobile-menu-li" @click="tabClick(1)"><div class="mobile-menu-title" :class="{ active : activeStr == 'about'}">ABOUT US</div></li>
				<li class="mobile-menu-li" @click="tabClick(2)"><div class="mobile-menu-title" :class="{ active : activeStr == 'product'}">PRODUCT</div></li>
				<li class="mobile-menu-li" @click="tabClick(4)"><div class="mobile-menu-title" :class="{ active : activeStr == 'contact'}">CONTACT</div></li>
				<li class="mobile-menu-li" @click="unFoldChild(4)">
					<div class="mobile-menu-title">
						<span>LANGUAGE</span>
						<i class="mobile-menu-fold" :class="[ childUnFold === 4 && 'active' ]"></i>
					</div>
					<ol class="mobile-menu-down" :class="[ childUnFold === 4 && 'active' ]">
						<li class="menu-down-li" @click="changeLanguage('cn')">简体中文</li>
						<li class="menu-down-li active">English</li>
					</ol>
				</li>
			</ul>
		</div>
	</header>
</template>

<script>
	export default {
		name: 'headerView',
		props: ['activeStr'],
		data() {
			return {
				current: localStorage.getItem('currentIndex') || 0,
				active: false,
				languageActive: '',
				inputVal: '',
				inputActive: false,
				isUnFold: false,
				isMobile: false,
				childUnFold: null
			}
		},
		mounted () {
			document.body.style.overflow = ''
			this.languageActive = localStorage.getItem('languageSet') || 'zh'
			this.resize()
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy () {
			window.removeEventListener('resize', this.resize)
		},
		methods: {
			resize () {
				const width = document.documentElement.clientWidth || document.body.clientWidth
				if (width > 800) {
					this.isMobile = false
				} else {
					this.isMobile = true
				}
			},
			toggleUnFold () {
				this.isUnFold = !this.isUnFold
				this.$emit('unfold', this.isUnFold)
				if (this.isUnFold) {
					document.body.style.overflow = 'hidden'
				} else {
					document.body.style.overflow = ''
				}
			},
			touchHandle (e) {
				e.preventDefault()
			},
			unFoldChild (index) {
				if (this.childUnFold == index) {
					this.childUnFold = null
				} else {
					this.childUnFold = index
				}
			},
			toHome () {
				this.$router.push('/en/home')
			},
			tabClick(index) {
				if (index == 0 || index == 1 || index == 2 || index == 4) {
					this.current = index
					localStorage.setItem('currentIndex', index)
				}
				if (index == 0) {
					this.$router.push('/en/home')
				} else if (index == 1) {
					this.$router.push('/en/about')
				} else if (index == 2) {
					this.$router.push('/en/product')
				} else if (index == 4) {
					this.$router.push('/en/contact')
				} else if (index == 5) {
					this.$router.push('/en/news')
				}
			},
			changeLanguage () {
       let url = this.$route.fullPath.replace('/en', '')
        if (!url) {
          url = '/'
        }
        this.$router.push(url)
			},
			search () {
				this.inputVal = ''
				this.inputActive = true
				setTimeout(() => {
					this.$refs.input.focus()
				}, 300)
			},
			close () {
				this.inputVal = ''
				this.inputActive = false
			}
		}
	}
</script>
<style lang="scss" scoped>
@import './index.scss';
.header .header_bg {
	background: url('../../assets/images/header-bgc-en.jpg') no-repeat;
}
.header
.header-area
.header-bar-right
.header-bar-search
.search-main
.search-btn {
	background: url('../../assets/images/icons/search-icon-light.svg') no-repeat;
    background-size: 50%;
    background-position: 10px;

	&.inputActive {
		background: rgba(255,255,255,.7) url('../../assets/images/icons/search-icon.svg') no-repeat;
		background-size: 50%;
		background-position: 10px;
	}
}
.header
.header-area
.header-bar-right
.language_change_box
.language-icon {
	background: url('../../assets/images/icons/language-icon-light.svg') no-repeat;
	background-size: 100%;
}

.header
.header-area
.header-bar-center
.navbar
.nav-item
.nav-link {
	color: #fff;
	opacity: .6;

	&:hover {
		opacity: 1;
	}

	&.active {
		opacity: 1;
	}
}

.header
.header-area
.header-bar-right
.menuIcon {
	background-color: #fff;

	&:before {
		background-color: #fff;
	}
	&:after {
		background-color: #fff;
	}
}

@media screen and (max-width:1300px) {
	.header-bar-search {
		width: 180px!important;
	}
}
</style>