const info={
  ZH: {  //中文翻译
    contactWay: {
      contact1: {
        title: '消费者联系',
        info: [
          { title: '全国免费客服热线', info: '400-800-1234' },
          { title: '客服邮箱', info: 'lignlang@163.com' }
        ]
      },
      contact2: {
        title: '商业合作',
        info: [
          { title: '销售业务合作', info: '+86 168-8888-6666（汪先生）' },
          { title: '销售业务合作', info: '+86 186-6666-8888（索先生）' },
          { title: '供应链合作', info: '+86 168-8888-6666（汪先生）' },
          { title: '邮箱', info: 'linglang88@163.com' },
          { title: '媒体合作', info: 'linglang88@163.com（我们会认真阅读每一封邮件，但无法保证每封都会回复）' }
        ]
      }
    }
  },
  EN: {  //英文翻译
    contactWay: {
      contact1: {
        title: '消费者联系',
        info: [
          { title: '全国免费客服热线', info: '400-800-1234' },
          { title: '客服邮箱', info: 'lignlang@163.com' }
        ]
      },
      contact2: {
        title: '商业合作',
        info: [
          { title: '销售业务合作', info: '+86 168-8888-6666（汪先生）' },
          { title: '销售业务合作', info: '+86 186-6666-8888（索先生）' },
          { title: '供应链合作', info: '+86 168-8888-6666（汪先生）' },
          { title: '邮箱', info: 'linglang88@163.com' },
          { title: '媒体合作', info: 'linglang88@163.com（我们会认真阅读每一封邮件，但无法保证每封都会回复）' }
        ]
      }
    }
  }
}
export default info