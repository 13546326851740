const info={
  ZH: {  //中文翻译
    crumbs: ['首页', 'AAV产品'],
    classify: ['震动棒', '跳蛋', '阳具', '飞机杯', '倒模', '舌舔'],
    classifyTitle: '情趣用品',
    goods: {
      title: '天使震动棒',
      info: 'C点G点同时高潮，强震酥麻',
      way: '购买'
    }
  },
  EN: {  //英文翻译
    crumbs: ['home', 'AVV product'],
    classify: ['vibrator', 'love egg', 'membrum', 'masturbation cup', 'reverse mould', 'tongue licking'],
    classifyTitle: 'sex toy',
    goods: {
      title: '天使震动棒',
      info: 'C点G点同时高潮，强震酥麻',
      way: '购买'
    }
  }
}
export default info