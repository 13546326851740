<template>
    <footer class="footer">
      <div class="footer-area">
        <div class="footer-area-top">
          <div class="footer-area-top-con center_box_linl wow animate__animated animate__slideInUp">
            <!-- <div class="mod-help-list" v-if="!isMobile">
              <dl class="mod-help-dl" v-for="(item, index) in modHelpList" :key="item.title + index">
                <dt class="footer_small_title">{{ item.title }}</dt>
                <dd class="mod-help-dd" v-for="A in item.children" :key="A.text"><span class="mod-help-text">{{ A.text }}</span></dd>
              </dl>
            </div>
            <ul class="mobile-menu-ul en" v-if="isMobile">
              <li class="mobile-menu-li" v-for="(item, index) in modHelpList" :key="item.title + index" @click="unFoldChild(index)">
                <div class="mobile-menu-title">
                  <span>{{item.title}}</span>
                  <i class="mobile-menu-fold" :class="[ childUnFold === index && 'active' ]"></i>
                </div>
                <ol class="mobile-menu-down" :class="[ childUnFold === index && 'active' ]">
                  <li class="menu-down-li" v-for="A in item.children" :key="A.text">{{ A.text }}</li>
                </ol>
              </li>
            </ul> -->
            <div class="contact-us">
              <div class="footer_small_title" v-if="!isMobile">CONTACTUS</div>
              <div class="contact-us-con">
                <div class="contact-us-item">
                  <div class="phone-icon"></div>
                  <div class="phone-info">
                    <span>Service Phone：</span>
                    <p class="phone-number">0086-400-8655-882</p>
                  </div>
                </div>
                <div class="contact-us-item">
                  <div class="phone-icon email"></div>
                  <div class="phone-info">
                    <span>Email：</span>
                    <p class="phone-number email">aavibeofficial@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="contact-way">
              <div class="contact-way-item" @mouseenter="showQrcode(1)" @mouseleave="hideQrcode">
                <div class="contact-way-item-icon wb-icon"></div>
                <div class="contact-way-item-qrcode" :class="[qrcodeShowIndex == 1 && 'active']">
                  <img src="../assets/images/qrcode.png" alt="">
                </div>
              </div>
              <div class="contact-way-item" @mouseenter="showQrcode(2)" @mouseleave="hideQrcode">
                <div class="contact-way-item-icon wx-icon"></div>
                <div class="contact-way-item-qrcode" :class="[qrcodeShowIndex == 2 && 'active']">
                  <img src="../assets/images/qrcode.png" alt="">
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="footer-area-bottom">
          <div class="copyright center_box_linl">
            <a class="toLink" href="https://beian.miit.gov.cn/" target="_blank">
              <span class="linkText">粤ICP备2023087726号-2</span>
            </a>
            <a class="toLink" href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002001195" target="_blank">
              <span class="linkText">粤公网安备44030002001195</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  name: 'footerArea',
  data() {
    return {
      qrcodeShowIndex: null,
      modHelpList: [
        {title: 'ABOUT US', children: [{text: 'OVERVIEW', link: ''},{text: 'HONOR', link: ''},{text: 'CULTURE', link: ''},{text: 'JOIN US', link: ''}]},
        {title: 'NEWS CENTER', children: [{text: 'TRENDS', link: ''},{text: 'ACTIVITY', link: ''}]},
        {title: 'SERVICE SUPPORT', children: [{text: 'SECURITY', link: ''},{text: 'DOWNLOAD', link: ''},{text: 'SHOPPING PLATFORM', link: ''}]}
      ],
      isMobile: false,
      childUnFold: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$wow.init()
    })
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  },
  methods:{
		resize () {
      // 是否为移动端
      const width = document.documentElement.clientWidth || document.body.clientWidth
			if (width > 800) {
				this.isMobile = false
			} else {
				this.isMobile = true
			}
		},
		unFoldChild (index) {
			if (this.childUnFold == index) {
				this.childUnFold = null
			} else {
				this.childUnFold = index
			}
		},
    showQrcode (index) {
      console.log(index)
      this.qrcodeShowIndex = index
    },
    hideQrcode () {
      this.qrcodeShowIndex = null
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
.footer {
  color: #e9e9e9;

  .footer-area .footer-area-top {
    background: url('../../assets/images/footer-bgc-en.png') no-repeat;
    background-size: cover;

    .footer_small_title {
      font-weight: 600;
    }

    .footer-area-top-con {
      font-size: 12px;

      .contact-us
      .contact-us-con {
        .contact-us-item:last-child {
          margin-right: 0;
        }

        .phone-icon {
          background: url('../../assets/images/icons/phone-icon-en.svg') no-repeat;
          background-size: 100%;
          &.email {
            background: url('../../assets/images/icons/email-icon-en.svg') no-repeat;
            background-size: 100%;
          }
        }
        .phone-number {
          color: #e9e9e9;
          font-size: 20px;
          font-weight: 400;
          margin-top: 8px;

          &.email {
            font-size: 16px;
          }
        }
      }
      .mod-help-list .mod-help-dl {
        width: 154px;

        .mod-help-dd {
          color: #e9e9e9;
          opacity: .8;
        }
      }
    }
  }

  .footer-area .footer-area-bottom {
    background-color: #090329;

    .copyright {
      font-size: 14px;
    }
  }
}

@media screen and (min-width:800px) and (max-width:1300px) {
  .footer .footer-area .footer-area-top .footer-area-top-con {
    .mod-help-list dl {
      width: 122px;
    }
    .contact-us {
      // margin-left:30px;
      .contact-us-con {
        .contact-us-item {
          margin-right:30px;
          .phone-number {
            font-size: 16px;
            &.email {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .footer .footer-area .footer-area-top .footer_small_title {
    font-size: 12px;
  }
}
@import './index-mobile.scss';
@media screen and (max-width: 800px) {
  .mobile-menu-ul.en {

    .mobile-menu-fold {
      &::before, &::after {
        background-color: #e9e9e9;
      }
    }

    .mobile-menu-down .menu-down-li {
      color: #e9e9e9;
      opacity: .8;
    }
  }
}
</style>