<template>
  <div class="swiper" :class="'updownCarousel-' + uuid">
    <div class="swiper-wrapper">
      <div class="swiper-slide sl-box" v-for="(item, index) in carouselList" :key="index">
        <div class="sl-img">
          <figure>
            <img :src="item.src" alt="">
          </figure>
        </div>
        <div class="sl-text">
          <strong>{{ item.title }}</strong>
          <p>{{ item.info }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="swiper-btn-prev" v-if="carouselList.length > 1" @click="prev"></div> -->
    <div class="swiper-btn-next" v-if="carouselList.length > 1" @click="next"></div>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'updownCarousel',
  props: ['carouselList'],
  data () {
    return {
      mySwiper: null,
      isMobile: false,
      slidesPerView: 1.4,
      uuid: '',
      delay: 2000,
      speed: 1000
    }
  },
  created () {
    this.uuid = uuidv4()
  },
  mounted() {
    this.resize()
    this.init()
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy() {
    if (this.mySwiper) {
      this.mySwiper.destroy()
    }
    window.removeEventListener('resize', this.resize)
  },
  methods: {
		resize () {
      // 是否为移动端
      const width = document.documentElement.clientWidth || document.body.clientWidth
			if (width > 800) {
				this.isMobile = false
        if (this.slidesPerView == 1) {
          this.slidesPerView = 1.4
          this.init()
        }
			} else {
				this.isMobile = true
        if (this.slidesPerView == 1.4) {
          this.slidesPerView = 1
          this.init()
        }
			}
		},
    init() {
      if (this.mySwiper) {
        this.mySwiper.destroy()
      }
      this.mySwiper = new Swiper('.updownCarousel-' + this.uuid, {
        autoplay: false,
        // autoplay : {
        //   delay:this.delay, // 自动切换间隔时间
        //   pauseOnMouseEnter: true,
        // },
        loop: true, // 循环切换
        slidesPerView: this.slidesPerView,
        autoHeight: true,
        speed:this.speed, // 切换速度
        // effect : 'coverflow',
        // coverflowEffect: {
        //   rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
        //   stretch: -250, //每个slide之间的拉伸值，越大slide靠得越紧。
        //   depth: 400, //slide的位置深度。值越大z轴距离越远，看起来越小。
        //   modifier: 1, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
        //   slideShadows: false //开启slide阴影。默认 true。
        // },
        // fadeEffect: {
        //   crossFade: true
        // },
        on: {
          // 开始切换下一张回调
          slideChange: function () {
          }
        }
      })
    },
    // 向左切换
    prev(){
      this.mySwiper.slidePrev()
    },
    // 向右切换
    next(){
      this.mySwiper.slideNext()
    }
  }
}
</script>

<style lang="scss" scoped>
  .swiper {
    position: relative;
    cursor: grab;

    .sl-box {
      width: 53.4%!important;
      margin-right: 18%;

      &:hover {
        strong {
          color: #830fc2;
        }

        p {
          color: #fff;
        }

        .sl-img img {
          transform: scale(1.1);
        }
      }
    }

    .swiper-slide,.swiper-slide-next {
      .sl-img figure {
        height: 75%;
      }

      &.swiper-slide-active {
        .sl-img figure {
          height: 100%;
        }
      }
    }

    .sl-img {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 0;
      line-height: 0;
      padding-bottom: 56.32%;
      overflow: hidden;

      figure {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 24px;
        height: 100%;
        transition: 1s;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        transition: all .3s;
      }
    }
    .sl-text {
      margin-top: 38px;

      strong {
        display: block;
        font-size: 30px;
        font-weight: normal;
        margin-bottom: 13px;
        transition: all .3s;
      }

      p {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.5);
        transition: all .3s;
        word-break: break-word;
      }
    }

    .swiper-btn-next {
      position: absolute;
      // left: 60%;
      // top: 40%;
      left: 52vw;
      top: 12vw;
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      border-radius: 50%;
      text-align: center;
      line-height: 44px;
      cursor: pointer;
      z-index: 20;
      transition: 0.3s;
      font-weight: bold;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-70%, -50%) rotate(-45deg);
        width: 10px;
        height: 10px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transition: 0.3s;
      }

      &:hover {
        color: #830fc2;
        border-color: #830fc2;

        &::after {
          border-color: #830fc2;
        }
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .swiper .sl-box {
      width: 45.31vw!important;
    }
  }
  @media screen and (max-width: 800px) {
    .swiper {
      .sl-box {
        min-width: 100%!important;
        margin-right: 0;
        padding-right: 20px;
  
        .sl-img figure {
          height: 100%;
        }
      }

      .sl-text {
        margin-top: 4.75vw;

        strong {
          font-size: 4vw;
        }

        p {
          font-size: 3vw;
        }
      }

      .swiper-btn-next {
        width: 7.73vw;
        height: 7.73vw;
        top: -12.25vw;
        left: 70vw;

        &::after {
          width: 2.25vw;
          height: 2.25vw;
        }
      }
    }
  }
</style>