<template>
  <div class="maskView-dcdsg">
    <transition name="overlay">
      <div class="mask">
        <!-- 遮罩层的内容 -->
        <div class="mask-con">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MaskView',
  mounted() {
    this.$nextTick(() => {
      this.$wow.init()
    })
  },
  data() {
    return {
      show: true
    }
  }
}
</script>

<style lang="scss" scoped>
.maskView-dcdsg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .mask {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    transition: opacity .3s;
  }
}

.overlay-enter-active {
  opacity: 1;
}
.overlay-leave-active {
  opacity: 0;
}
</style>
