<template>
	<div id="app">
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>
	</div>
</template>
<script>
	export default {
		name: 'app',
		data () {
			return {
			}
		},
		methods: {
			
		}
	}
</script>
<style lang="scss">
	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.2s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.official-code {
		position: fixed;
		bottom: 50%;
		right: 5.21vw;
		width: 8.7vw;
		height: 11.56vw;
		max-width: 167px;
		min-width: 84px;
		max-height: 222px;
		min-height: 111px;
		z-index: 999;
	}
</style>