<template>
  <div class="backTop">
    <div class="backTop-con" :class="[active && 'active']" @click="slideToTop">顶部</div>
  </div>
</template>

<script>
export default {
  name: 'backTop',
  data () {
    return {
      active: false, // 是否显示返回顶部按钮
      timer: null
    }
  },
  mounted () {
    this.scrollFun()
    window.addEventListener('scroll', this.scrollFun)
  },
  beforeCreate () {
    clearInterval(this.timer)
    this.timer = null
    window.removeEventListener('scroll', this.scrollFun)
  },
  methods: {
    // 滚动事件
    scrollFun () {
      const top = document.body.scrollTop || document.documentElement.scrollTop
      if (top > 200) {
        this.active = true
      } else {
        this.active = false
      }
    },
    // 返回顶部
    slideToTop () {
      if (this.timer) {
        return
      }
      this.timer = setInterval(() => {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 30)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if(osTop == 0){
          clearInterval(this.timer)
          this.timer = null
        }
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.backTop-con {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #f2f2f2;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  overflow: hidden;
  z-index: 9999;
  transform: scale(0.6);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  cursor: pointer;
  
  &.active {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }

  &.active:hover {
    background-color: #ddd;
  }
}
</style>