import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '@/views/home'
// import HomeViewEn from '@/views/home/index-en.vue'
import HomeView from '@/views/home'
import HomeViewEn from '@/views/home/index-en.vue'
Vue.use(VueRouter)

const language = navigator.language || navigator.browserLanguage || navigator.userLanguage
const redirectUrl = language === 'zh-CN' ? '/home' : '/en/home'
const routes = [
  {
    path: '/home',
    name: 'home',
    meta: {
      title: 'AAV 艾艾微'
    },
    component: HomeView
  },
  {
    path: '/en/home',
    name: 'homeEn',
    meta: {
      title: 'AAV'
    },
    component: HomeViewEn
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们'
    },
    component: () => import('@/views/about')
  },
  {
    path: '/en/about',
    name: 'aboutEn',
    meta: {
      title: 'about us'
    },
    component: () => import('@/views/about/index-en.vue')
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: 'AAV产品'
    },
    component: () => import('@/views/product')
  },
  {
    path: '/en/product',
    name: 'productEn',
    meta: {
      title: 'AAV product'
    },
    component: () => import('@/views/product/index-en.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: '联系我们'
    },
    component: () => import('@/views/contact')
  },
  {
    path: '/en/contact',
    name: 'contactEn',
    meta: {
      title: 'contact us'
    },
    component: () => import('@/views/contact/index-en.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '品牌动态'
    },
    component: () => import('@/views/home')
  },
  {
    path: '/en/news',
    name: 'newsEn',
    meta: {
      title: 'brand news'
    },
    component: () => import('@/views/home/index-en.vue')
  },
  {
    path: '/',
    redirect: redirectUrl
  },
  {
    path: '/en',
    redirect: '/en/home'
  }
]
 
const router = new VueRouter({
  mode: 'history',    
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => { 
  document.title = to.meta.title
  next()
})
export default router
