const info={
  ZH: {  //中文翻译
    carouselList: [require('@/assets/images/carousel2.jpg'), require('@/assets/images/carousel3.jpg')],
    moduleTitle: ['新品发布', '热销单品', '推荐好物'],
    goods: {
      good1: [
        { title: '天使点潮笔', info: ['CG共舞', '精准点潮', '强震酥麻'] },
        { title: '小鲸鱼跳蛋', info: ['调教女友', '隐形穿戴', '10频晨动'] },
        { title: '玫瑰舌舔', info: ['舌舔震动', '玩法多样', '玫瑰造型'] }
      ],
      good2: [
        { title: '小妖怪跳蛋', info: ['外出穿戴', '遥控刺激', '头尾双震'] },
        { title: '冰激凌乳房按摩器', info: ['越揉越大', '旋转按摩', '强力吸附'] },
        { title: '蝴蝶穿戴APP', info: ['外出穿戴', '智能遥控', '磁吸吸附'] },
        { title: '食人花震动棒', info: ['三点同潮', '饱满不撑大', '液态硅胶'] },
        { title: '一代大嘴伸缩棒', info: ['内外双享', '舌舔震颤', '多种模式'] }
      ],
      good3: [
        { title: '太空舱训练杯', info: ['紧致夹缩', '贯通包裹', '10频脉冲'] },
        { title: '玉兔乳房按摩器', info: ['科学丰胸', '强震撩乳', '不挑胸型'] },
        { title: 'E02震动笔APP', info: ['隐匿强震', '精准点触', '智能遥控'] },
        { title: '卡罗拉玫瑰肛塞APP', info: ['肛阴两用', '柔软易进', '远程遥控'] },
        { title: '小恐龙GK震动棒', info: ['吮吸强震', '内外同潮', ' 小巧可爱'] }
      ]
    },
    buyWay: {
      goTitle: '前往购买',
      tm: {
        name: '天猫购买',
        info: '从天猫旗舰店选购您的产品，享受送货上门服务，并提供售后服务。'
      },
      jd: {
        name: '京东购买',
        info: '从京东旗舰店选购您的产品，享受送货上门服务，并提供售后服务。'
      },
      pdd: {
        name: '拼多多购买',
        info: '从拼多多旗舰店选购您的产品，享受送货上门服务，并提供售后服务。'
      },
      Telephone: {
        name: '电话咨询',
        info: '从电话咨询选购您的产品，享受送货上门服务，并提供售后服务。'
      }
    }
  },
  EN: {  //英文翻译
    carouselList: [require('@/assets/images/carousel5.jpg'), require('@/assets/images/carousel1.jpg'), require('@/assets/images/carousel2.jpg')],
    moduleTitle: ['New Nroduct', 'Hot Sale', 'Recommend'],
    goods: {
      good1: [
        { title: '天使点潮笔', info: ['CG共舞', '精准点潮', '强震酥麻'] },
        { title: '小鲸鱼跳蛋', info: ['调教女友', '隐形穿戴', '10频晨动'] },
        { title: '玫瑰舌舔', info: ['舌舔震动', '玩法多样', '玫瑰造型'] }
      ],
      good2: [
        { title: '小妖怪跳蛋', info: ['外出穿戴', '遥控刺激', '头尾双震'] },
        { title: '冰激凌乳房按摩器', info: ['越揉越大', '旋转按摩', '强力吸附'] },
        { title: '蝴蝶穿戴APP', info: ['外出穿戴', '智能遥控', '磁吸吸附'] },
        { title: '食人花震动棒', info: ['三点同潮', '饱满不撑大', '液态硅胶'] },
        { title: '一代大嘴伸缩棒', info: ['内外双享', '舌舔震颤', '多种模式'] }
      ],
      good3: [
        { title: '太空舱训练杯', info: ['紧致夹缩', '贯通包裹', '10频脉冲'] },
        { title: '玉兔乳房按摩器', info: ['科学丰胸', '强震撩乳', '不挑胸型'] },
        { title: 'E02震动笔APP', info: ['隐匿强震', '精准点触', '智能遥控'] },
        { title: '卡罗拉玫瑰肛塞APP', info: ['肛阴两用', '柔软易进', '远程遥控'] },
        { title: '小恐龙GK震动棒', info: ['吮吸强震', '内外同潮', ' 小巧可爱'] }
      ]
    },
    buyWay: {
      goTitle: 'to buy',
      tm: {
        name: 'Tmall Purchase',
        info: 'Buy your products from Tmall flagship store, enjoy home delivery service, and provide after-sales service'
      },
      jd: {
        name: 'JD Purchase',
        info: 'Buy your products from JD flagship store, enjoy home delivery service, and provide after-sales service'
      },
      pdd: {
        name: 'PDD Purchase',
        info: 'Buy your products from PDD flagship store, enjoy home delivery service, and provide after-sales service'
      },
      Telephone: {
        name: 'Telephone',
        info: 'Buy your products from Telephone, enjoy home delivery service, and provide after-sales service'
      }
    }
  }
}
export default info