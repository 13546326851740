import header from "./header-area/index"
import footer from "./footer-area/index"
import home from "./home/index"
import product from "./product/index"
import about from "./about/index"
import contact from "./contact/index"

const ZH = {
  language: {
    name: '中文'
  },
  home: home.ZH,
  header: header.ZH,
  footer: footer.ZH,
  product: product.ZH,
  about: about.ZH,
  contact: contact.ZH
}

export default ZH