import Vue from 'vue'
import App from './App.vue'
import router from './router'
import components from '@/components/index'
// import animated from 'animate.css'
// Vue.use(animated)
import 'animate.css'

import "swiper/swiper-bundle.css"
// import "swiper/css/bundle"

import '@/assets/fonts/index.scss'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/reset.scss'

import { WOW } from 'wowjs' 
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 0, // default
  mobile: true, // default
  live: false,
  callback: function (box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
})

// 语言切换
import VueI18n from 'vue-i18n'
import zh from '@/utils/language/zh'
import en from '@/utils/language/en'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale:localStorage.getItem('languageSet') || 'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
    messages:{
      'zh': zh,
      'en': en
    }
})

Vue.use(components)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
