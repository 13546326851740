<template>
  <div class="about">
    <head-area :activeStr="'home'" class="about_head_area" :class="[!isMobile && 'opacity']" @unfold="flag => isUnFold = flag"></head-area>
    <!--轮播-->
    <video-carousel v-if="!isUnFold" :carouselList="carouselList"></video-carousel>
    <!-- <Carousel :carouselList="carouselList"></Carousel> -->
    <!-- <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide sl-box" v-for="(item, index) in carouselList" :key="index">
          <img class="lb-img" :src="item" alt="">
        </div>
      </div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div> -->
    <!-- <div class="pbanner">
        <video src="../../assets/videos/AAV-brand.mp4" loop autoplay muted></video>
    </div> -->
    <!-- <video-carousel :carouselList="carouselList"></video-carousel> -->
    <div class="about-main">
        <div class="product-item wow animate__animated animate__slideInLeft">
            <div class="product-info-text wow animate__animated animate__slideInUp">
                <div class="product-info-text-title">品牌简介</div>
                <div class="product-info-text-manifesto">
                    <span>爱</span>
                    <span>平等</span>
                    <span>自由</span>
                    <span>有趣</span>
                </div>
                <p>AAV作为情趣行业新锐品牌。以“探索奇妙乐趣，创</p>
                <p>造卓越品质”为发展理念。坚持以用户价值为中心，</p>
                <p>以打造既有趣且不随波逐流的产品为初衷，持续探索</p>
                <p>用户与情趣生活之间的情感连接，为用户提供自由、</p>
                <p>平等、有趣的情趣生活体验。</p>
            </div>
            <div class="product-item-bgc intro"></div>
            <!-- <img src="../../assets/images/about/product-notext1.jpg" alt=""> -->
        </div>
        <div class="brand-main">
            <div class="brand-main-title">{{ $t('about.brandList.brand1.title') }}</div>
            <updown-carousel :carouselList="branCultureList"></updown-carousel>
        </div>
        <div class="product-item wow animate__animated animate__slideInRight">
            <div class="product-info-text wow animate__animated animate__slideInUp">
                <div class="product-info-text-title">品牌发展</div>
                <div class="product-info-text-manifesto">
                    <span>爱</span>
                    <span>平等</span>
                    <span>自由</span>
                    <span>有趣</span>
                </div>
                <p>品牌产品已进入北美洲、欧洲、南美洲、亚洲等多个海外</p>
                <p>市场，服务于全球各地的用户。经过不断的努力和发展，</p>
                <p>AAV现已成为全球情趣用品品牌的后起之秀。</p>
            </div>
            <div class="product-item-bgc develop"></div>
            <!-- <img src="../../assets/images/about/product-notext2.jpg" alt=""> -->
        </div>
        <div class="brand-main">
            <div class="brand-main-title">品牌故事</div>
            <updown-carousel :carouselList="branIdeaList"></updown-carousel>
        </div>
        <div class="product-item wow animate__animated animate__slideInLeft">
            <div class="product-info-text wow animate__animated animate__slideInUp">
                <div class="product-info-text-title">品牌愿景</div>
                <div class="product-info-text-manifesto special">
                    <span>成为全球情趣用品行业的领跑者</span>
                </div>
            </div>
            <div class="product-item-bgc story"></div>
            <!-- <img src="../../assets/images/about/product-notext3.jpg" alt=""> -->
        </div>
    </div>
    <footer-area></footer-area>
    <!-- <backtop-view /> -->
  </div>
</template>

<script>
import videoCarousel from '@/components/carousel/video-carousel.vue'
// import Carousel from '@/components/carousel/Carousel.vue'
import updownCarousel from '@/components/carousel/updown-carousel.vue'

export default {
    name: 'homeView',
    components: { videoCarousel, updownCarousel },
    data () {
        return {
            // carouselList: [require('@/assets/videos/product-video.mp4')],
            carouselList: [require('@/assets/videos/productVideo.mp4')],
            branCultureList: [
                {
                    src: require('@/assets/images/about/carousel1.jpg'),
                    title: this.$t('about.brandList.brand1.arr')[2].title,
                    info: 'AAV 让爱更有趣'
                },
                {
                    src: require('@/assets/images/about/carousel2.jpg'),
                    title: this.$t('about.brandList.brand1.arr')[0].title,
                    info: '探索奇妙乐趣，创造卓越品质'
                },
                // {
                //     src: require('@/assets/images/about/carousel2.jpg'),
                //     title: this.$t('about.brandList.brand1.arr')[1].title,
                //     info: this.$t('about.brandList.brand1.arr')[1].info
                // },
                {
                    src: require('@/assets/images/about/carousel3.jpg'),
                    title: '品牌使命',
                    info: '为用户提供自由、平等、有趣的爱'
                }
            ],
            branIdeaList: [
                {
                    src: require('@/assets/images/about/carousel4.jpg'),
                    title: '缘起',
                    info: '打破传统束缚，为“爱”而生，致力为用户提供自由、平等、有趣的“爱”，是AAV诞生的起点'
                },
                {
                    src: require('@/assets/images/about/carousel5.jpg'),
                    title: '现在',
                    info: '以用户价值为中心，拒绝盲从，敢于不同，将爱与趣味相结合，解锁更多快乐方式'
                },
                {
                    src: require('@/assets/images/about/carousel6.jpg'),
                    title: '未来',
                    info: '秉承“探索奇妙乐趣，创造卓越品质”的理念'
                }
            ],
            active: false,
            isMobile: false,
            isUnFold: false
        }
    },
    mounted() {
        this.resize()
        this.$nextTick(() => {
            this.$wow.init()
            setTimeout(() => {
                const top = document.documentElement.scrollTop
                document.documentElement.scrollTop = top ? 0 : 1
            }, 100)
        })
        // this.scrollFun()
		window.addEventListener('resize', this.resize)
    },
	beforeDestroy () {
		window.removeEventListener('resize', this.resize)
	},
    methods: {
		resize () {
            // 是否为移动端
            const width = document.documentElement.clientWidth || document.body.clientWidth
			if (width > 800) {
				this.isMobile = false
			} else {
				this.isMobile = true
			}
		}
		// scrollFun () {
		// 	const top = document.documentElement.scrollTop
		// 	if (top >= window.innerHeight - 80) {
		// 		this.active = false
		// 	} else {
		// 		this.active = true
		// 	}
		// }
    }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
@import './index-mobile.scss';
</style>