<template>
    <footer class="footer">
      <div class="footer-area">
        <div class="footer-area-top">
          <div class="footer-area-top-con center_box_linl wow animate__animated animate__slideInUp">
            <!-- <div class="mod-help-list" v-if="!isMobile">
              <dl class="mod-help-dl" v-for="(item, index) in modHelpList" :key="item.title + index">
                <dt class="footer_small_title">{{ item.title }}</dt>
                <dd class="mod-help-dd" v-for="A in item.children" :key="A.text"><span class="mod-help-text">{{ A.text }}</span></dd>
              </dl>
            </div>
            <ul class="mobile-menu-ul" v-if="isMobile">
              <li class="mobile-menu-li" v-for="(item, index) in modHelpList" :key="item.title + index" @click="unFoldChild(index)">
                <div class="mobile-menu-title">
                  <span>{{item.title}}</span>
                  <i class="mobile-menu-fold" :class="[ childUnFold === index && 'active' ]"></i>
                </div>
                <ol class="mobile-menu-down" :class="[ childUnFold === index && 'active' ]">
                  <li class="menu-down-li" v-for="A in item.children" :key="A.text">{{ A.text }}</li>
                </ol>
              </li>
            </ul> -->
            <div class="contact-us">
              <div class="footer_small_title" v-if="!isMobile">{{ $t('footer.contact')[0] }}</div>
              <div class="contact-us-con">
                <div class="contact-us-item">
                  <div class="phone-icon"></div>
                  <div class="phone-info">
                    <span>{{ $t('footer.contact')[1] }}</span>
                    <p class="phone-number">400-8655-882</p>
                  </div>
                </div>
                <div class="contact-us-item">
                  <div class="phone-icon email"></div>
                  <div class="phone-info">
                    <span>邮箱：</span>
                    <p class="phone-number email">aavibeofficial@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="contact-way">
              <div class="contact-way-item" @mouseenter="showQrcode(1)" @mouseleave="hideQrcode">
                <div class="contact-way-item-icon wx-icon"></div>
                <div class="contact-way-item-qrcode" :class="[qrcodeShowIndex == 1 && 'active']">
                  <img src="../assets/images/qrcode.png" alt="">
                </div>
              </div>
              <div class="contact-way-item" @mouseenter="showQrcode(2)" @mouseleave="hideQrcode">
                <div class="contact-way-item-icon wb-icon"></div>
                <div class="contact-way-item-qrcode" :class="[qrcodeShowIndex == 2 && 'active']">
                  <img src="../assets/images/qrcode.png" alt="">
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="footer-area-bottom">
          <div class="copyright center_box_linl">
            <a class="toLink" href="https://beian.miit.gov.cn/" target="_blank">
              <span class="linkText">粤ICP备2023087726号-2</span>
            </a>
            <a class="toLink" href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002001195" target="_blank">
              <span class="linkText">粤公网安备44030002001195</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  name: 'footerArea',
  data() {
    return {
      qrcodeShowIndex: null,
      modHelpList: [
        {title: '关于我们', children: [{text: '企业概况', link: ''},{text: '企业荣誉', link: ''},{text: '企业文化', link: ''},{text: '加入我们', link: ''}]},
        {title: '新闻中心', children: [{text: '企业动态', link: ''},{text: '最新活动', link: ''}]},
        {title: '关于我们', children: [{text: '产品防伪', link: ''},{text: '下载中心', link: ''},{text: '网点地图', link: ''}]}
      ],
      isMobile: false,
      childUnFold: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$wow.init()
    })
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  },
  methods:{
		resize () {
      // 是否为移动端
      const width = document.documentElement.clientWidth || document.body.clientWidth
			if (width > 800) {
				this.isMobile = false
			} else {
				this.isMobile = true
			}
		},
		unFoldChild (index) {
			if (this.childUnFold == index) {
				this.childUnFold = null
			} else {
				this.childUnFold = index
			}
		},
    showQrcode (index) {
     this.qrcodeShowIndex = index
    },
    hideQrcode () {
     this.qrcodeShowIndex = null
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';

@media screen and (min-width:800px) and (max-width:1300px) {
  .footer .footer-area .footer-area-top .footer-area-top-con {
    .mod-help-list dl {
      width: 104px;
      font-size: 14px;
    }
    .contact-us {
      // margin-left:60px;
      .contact-us-con {
        .contact-us-item {
          margin-right:60px;
          .phone-number {
            font-size: 16px;
            &.email {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .footer .footer-area .footer-area-top .footer_small_title {
    font-size: 14px;
  }
  .footer .footer-area .footer-area-bottom .copyright {
    font-size: 14px;
  }
}

@import './index-mobile.scss';
</style>