<template>
  <div class="swiper" :class="'videoCarousel-' + uuid" :style="{ height: isMobile ? 'auto' : swiperHeight + 'px' }">
    <div class="swiper-wrapper">
      <div class="swiper-slide sl-box" v-for="(item, index) in carouselList" :key="index">
        <video
          :ref="'lb-video-' + index" class="lb-video"
          :controls="false"
          @loadedmetadata="loadedmetadata('lb-video-' + index, index)" @error="videoFun"
          muted
          webkit-playsinline="true"
          playsinline="true"
          x5-playsinline="true"
          x5-video-player-fullscreen="true"
          x5-video-player-type="h5"
          x5-video-orientation="portraint"
        >
          <source :src="item" type='video/mp4' />
        </video>
      </div>
    </div>
    <div class="swiper-pagination" v-show="!isMobile">
      <div class="swiper-pagination-con">
        <div v-for="(item, index) in carouselList.length" :key="index" class="item" :class="[curIndex == index && 'active', carouselList.length - 1 == index && 'mr0']" :style="{width: itemWidth + 'px'}" @click="clickItem(index)">
          <div v-if="curIndex == index" :style="{width: isMobile ? 0 :curwidth + 'px'}" class="item-active"></div>
        </div>
        <div class="btn play" v-show="!isPlay" @click="play"></div>
        <div class="btn pause" v-show="isPlay" @click="pause"></div>
      </div> 
    </div>
    <!-- <div class="swiper-button-prev" v-if="carouselList.length > 1" @click="prev"></div>
    <div class="swiper-button-next" v-if="carouselList.length > 1" @click="next"></div> -->
  </div>
</template>

<script>
import Swiper from 'swiper/bundle'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'videoCarousel',
  props: ['carouselList', 'videoTime'],
  data () {
    return {
      list: [],
      mySwiper: null,
      uuid: '',
      isFirst: true,
      swiperHeight: 0,
      curIndex: 0,
      delay: 2000,
      speed: 0,
      timer: null,
      autoplayTimer: null,
      curwidth: 150,
      itemWidth: 150,
      isPlay: true,
      countTimeObj: {},
      isMobile: true
    }
  },
  created () {
    this.uuid = uuidv4()
  },
  beforeMount () {
    // this.videoTime = this.carouselList.map(item => {
    //   return this.getVideoDuration(item)
    // })
    // console.log(this.videoTime[0])
  },
  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.autoplayTimer)
    if (this.mySwiper) {
      this.mySwiper.destroy()
    }
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      // 是否为移动端
      const width = document.documentElement.clientWidth || document.body.clientWidth
			if (width > 800) {
				this.isMobile = false
			} else {
				this.isMobile = true
			}
      if (!this.isMobile) {
        this.getSwiperHeight()
      }
    },
    getSwiperHeight () {
      const screenHeight = document.documentElement.clientHeight
      this.swiperHeight = screenHeight - 35
    },
    videoFun () {
      // alert(JSON.stringify(e))
    },
    // 获取视频的总时长
    loadedmetadata (str, index) {
      const duration =  this.$refs[str][0].duration
      if (duration && duration > 0) {
        this.countTimeObj[index] = duration
      }
    },
    init() {
      const that = this
      if (this.mySwiper) {
        this.mySwiper.destroy()
      }
      this.mySwiper = new Swiper('.videoCarousel-' + this.uuid, {
        autoplay: false,
        // autoplay : {
        //   delay:this.delay, // 自动切换间隔时间
        //   pauseOnMouseEnter: true,
        // },
        // loop: true, // 循环切换
        speed:this.speed, // 切换速度
        effect : 'fade', // 切换效果
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: ".swiper-pagination",
          type : 'custom',
          clickable: true
        },
        on: {
          // 开始切换下一张回调
          slideChange: function () {
            that.curIndex = this.realIndex
            if (this.realIndex != 0 && that.isFirst) {
              that.isFirst = false
            }
            that.isPlay = true
            that.$refs['lb-video-' + that.curIndex][0].currentTime = 0
            that.$refs['lb-video-' + that.curIndex][0].play()
            that.setWidth(that.itemWidth, that.delay, true)
          }
        },
      })
      this.$refs['lb-video-' + this.curIndex][0].currentTime = 0
      this.$refs['lb-video-' + this.curIndex][0].play()
      this.setWidth(this.itemWidth, this.delay, true)
    },
    // 自动播放
    autoplaySwiper (curDelay = 0) {
      if (this.carouselList.length < 2) {
        return
      }
      let delay = this.delay + (this.isFirst ? 0 : this.speed)
      clearInterval(this.autoplayTimer)
      this.autoplayTimer = setInterval(() => {
        this.mySwiper.slideNext()
      }, delay - curDelay < 0 ? 0 : delay - curDelay)
    },
    // 点击分页器按钮
    clickItem (index) {
      if (this.carouselList.length < 2) {
        return
      }
      // this.$refs['lb-video-' + this.curIndex][0].pause()
      this.curIndex = index
      this.mySwiper.slideTo(this.curIndex, this.speed)
    },
    // 暂停自动播放
    pause () {
      // this.mySwiper.autoplay.stop()
      this.$refs['lb-video-' + this.curIndex][0].pause()
      this.isPlay = !this.isPlay
      // clearInterval(this.autoplayTimer)
      clearInterval(this.timer)
    },
    // 开启自动播放
    play () {
      // this.mySwiper.autoplay.start()
      this.$refs['lb-video-' + this.curIndex][0].play()
      this.isPlay = !this.isPlay
      // const step = this.itemWidth / (this.delay + (this.isFirst ? 0 : this.speed)) // 计算1毫秒要走多少像素
      // let delay = 0
      // // 获取暂停时已经走了多少毫秒
      // if (this.curwidth < this.itemWidth) {
      //   delay = (this.itemWidth - this.curwidth) / step
      // } else if (this.curwidth == this.itemWidth) {
      //   delay = this.itemWidth / step
      // }
      // this.autoplaySwiper(delay)
      this.setWidth(this.itemWidth, this.delay) 
    },
    // 向左切换
    prev(){
      if (this.carouselList.length < 2) {
        return
      }
      // this.$refs['lb-video-' + this.curIndex][0].pause()
      // this.mySwiper.slidePrev()
      this.curIndex -= 1
      if (this.curIndex < 0) {
        this.curIndex = this.carouselList.length - 1
      }
      this.mySwiper.slideTo(this.curIndex, this.speed)
    },
    // 向右切换
    next(){
      if (this.carouselList.length < 2) {
        return
      }
      // this.$refs['lb-video-' + this.curIndex][0].pause()
      // this.mySwiper.slideNext()
      this.curIndex += 1
      if (this.curIndex > this.carouselList.length - 1) {
        this.curIndex = 0
      }
      this.mySwiper.slideTo(this.curIndex, this.speed)
    },
    // 分页器进度条
    setWidth (maxWidth, time, isInit)  {
      if (isInit) {
        this.curwidth = this.itemWidth
      }
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.countTimeObj[this.curIndex] == undefined) {
          return
        }
        const step = maxWidth / ((this.countTimeObj[this.curIndex] * 1000) + (this.isFirst ? 0 : this.speed)) * 10
        this.curwidth -= step
        if (this.curwidth <= 0) {
          this.curwidth = 0
          // this.$refs['lb-video-' + this.curIndex][0].pause()
          clearInterval(this.timer)
          if (this.carouselList.length < 2) {
            this.$refs['lb-video-' + this.curIndex][0].currentTime = 0
            this.$refs['lb-video-' + this.curIndex][0].play()
            this.setWidth(this.itemWidth, this.delay, true)
          } else {
            this.next()
          }
        }
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
  .swiper {
    position: relative;
    margin-bottom: 70px;

    .sl-box {
      width: 100%;
      height: 100%;
    }

    .lb-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #000;
    }

    .swiper-pagination {
      position: absolute;
      left: 0;
      bottom: -20px;
      display: flex;
      justify-content: center;

      .swiper-pagination-con {
        position: relative;
        display: flex;

        .item {
          position: relative;
          height: 6px;
          margin-right: 80px;
          background: #dee9eb;
          cursor: pointer;
          transition: all .3s;

          &.active {
            background: linear-gradient(to right, #eaaef0 20%, #8a9af1 40%, #7fffe8 80%);
          }

          &.mr0 {
            margin: 0;
          }

          & > .item-active {
            position: absolute;
            right: 0;
            top: 0;
            width: 0;
            height: 100%;
            background: #dee9eb;
          }
        }

        .btn {
          position: absolute;
          width: 16px;
          height: 16px;
          top: 50%;
          right: -40px;
          transform: translateY(-50%);
          background-size: 100%;
          cursor: pointer;

          &.play {
            background: url('../../assets/images/icons/play.png') no-repeat;
          }
          &.pause {
            background: url('../../assets/images/icons/pause.png') no-repeat;
          }
        }
      }
    }

    .swiper-button-next, .swiper-button-prev {
      position: absolute;
      width: 90px;
      height: 90px;
      transform: rotate(45deg);
    }

    .swiper-button-next {
      right: 180px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 0;
        border-left: 22px solid #fff;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .swiper-button-prev {
      left: 180px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 0;
        border-left: 22px solid #fff;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }
  }

  @media screen and (max-width:1300px) {
    .swiper {
      .swiper-button-next, .swiper-button-prev {
        width: 60px;
        height: 60px;
      }
  
      .swiper-button-next {
        &::after {
          border-left: 18px solid #fff;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
        }
      }
      .swiper-button-prev {
        &::after {
          border-left: 18px solid #fff;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
        }
      }
    }
  }

  @media screen and (max-width:800px) {
    .swiper {
      margin-bottom: 20px;
    }
  }
</style>