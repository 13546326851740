<template>
	<!--头部-->
	<header class="header">
		<div class="header_bg"></div>
		<div class="header-area center_box_linl">
			<div class="header-bar-left">
				<div class="brand_text" @click="toHome">
					<img class="brand_img" src="../../assets/images/brand-img.png" alt="">
				</div>
			</div>
			<div class="header-bar-center">
				<div class="navbar" v-if="!isMobile">
					<div class="nav-item" @click="tabClick(0)"><span :class="{ active : activeStr == 'home'}" class="nav-link" :text="$t('header.navName')[0]">{{ $t('header.navName')[0] }}</span></div>
					<div class="nav-item" @click="tabClick(1)"><span :class="{ active : activeStr == 'about'}" class="nav-link" :text="$t('header.navName')[1]">{{ $t('header.navName')[1] }}</span></div>
					<div class="nav-item" @click="tabClick(2)"><span :class="{ active : activeStr == 'product'}" class="nav-link" :text="$t('header.navName')[2]">{{ $t('header.navName')[2] }}</span></div>
					<!-- <div class="nav-item" @click="tabClick(3)"><span :class="{ active : activeStr == 'cooperate'}" class="nav-link" :text="$t('header.navName')[3]">{{ $t('header.navName')[3] }}</span></div> -->
					<div class="nav-item" @click="tabClick(4)"><span :class="{ active : activeStr == 'contact'}" class="nav-link" :text="$t('header.navName')[4]">{{ $t('header.navName')[4] }}</span></div>
					<!-- <div class="nav-item" @click="tabClick(5)"><span :class="{ active : activeStr == 'news'}" class="nav-link" :text="$t('header.navName')[5]">{{ $t('header.navName')[5] }}</span></div> -->
				</div>
			</div>
			<div class="header-bar-right">
				<div class="header-bar-right-one" v-if="!isMobile">
					<div class="header-bar-search">
						<!-- <div class="line"></div> -->
						<div class="search-main">
							<div class="inputBox" :class="inputActive && 'inputActive'">
								<input class="input" :value="inputVal" ref="input" type="text" :placeholder="$t('header.placeholder')">
								<i class="closeIcon" @click="close">×</i>
							</div>
							<div class="search-btn" :class="inputActive && 'inputActive'" @click="search"></div>
						</div>
					</div>
					<div class="language_change_box">
						<i class="language-icon"></i>
						<!-- <div class="language_item active">简体中文</div>
						<div class="language_item" @click="changeLanguage('en')">English</div> -->
						<ul class="language_list">
							<li class="language_li active">简体中文</li>
							<li class="language_li" @click="changeLanguage('en')">English</li>
						</ul>
					</div>
				</div>
				<div v-else class="menuBtn" :class="[ isUnFold && 'active' ]" @click="toggleUnFold">
					<span class="menuIcon"></span>
				</div>
			</div>
		</div>
		<div class="mobile-menu" :class="[ isUnFold && 'active' ]">
			<ul class="mobile-menu-ul">
				<li class="mobile-menu-li" @click="tabClick(0)"><div class="mobile-menu-title" :class="{ active : activeStr == 'home'}">首页</div></li>
				<li class="mobile-menu-li" @click="tabClick(1)"><div class="mobile-menu-title" :class="{ active : activeStr == 'about'}">关于我们</div></li>
				<li class="mobile-menu-li" @click="tabClick(2)"><div class="mobile-menu-title" :class="{ active : activeStr == 'product'}">AAV产品</div></li>
				<li class="mobile-menu-li" @click="tabClick(4)"><div class="mobile-menu-title" :class="{ active : activeStr == 'contact'}">联系我们</div></li>
				<li class="mobile-menu-li" @click="unFoldChild(4)">
					<div class="mobile-menu-title">
						<span>语言</span>
						<i class="mobile-menu-fold" :class="[ childUnFold === 4 && 'active' ]"></i>
					</div>
					<ol class="mobile-menu-down" :class="[ childUnFold === 4 && 'active' ]">
						<li class="menu-down-li active">简体中文</li>
						<li class="menu-down-li" @click="changeLanguage('en')">English</li>
					</ol>
				</li>
			</ul>
		</div>
	</header>
</template>

<script>
	export default {
		name: 'headerView',
		props: ['activeStr'],
		data() {
			return {
				current: localStorage.getItem('currentIndex') || 0,
				active: false,
				languageActive: '',
				inputVal: '',
				inputActive: false,
				isUnFold: false,
				isMobile: false,
				childUnFold: null
			}
		},
		mounted () {
			document.body.style.overflow = ''
			this.languageActive = localStorage.getItem('languageSet') || 'zh'
			this.resize()
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy () {
			window.removeEventListener('resize', this.resize)
		},
		methods: {
			resize () {
				const width = document.documentElement.clientWidth || document.body.clientWidth
				if (width > 800) {
					this.isMobile = false
				} else {
					this.isMobile = true
				}
			},
			toggleUnFold () {
				this.isUnFold = !this.isUnFold
				this.$emit('unfold', this.isUnFold)
				if (this.isUnFold) {
					document.body.style.overflow = 'hidden'
				} else {
					document.body.style.overflow = ''
				}
			},
			unFoldChild (index) {
				if (this.childUnFold == index) {
					this.childUnFold = null
				} else {
					this.childUnFold = index
				}
			},
			toHome () {
				this.$router.push('/home')
			},
			tabClick(index) {
				if (index == 0 || index == 1 || index == 2 || index == 4) {
					this.current = index
					localStorage.setItem('currentIndex', index)
				}
				if (index == 0) {
					this.$router.push('/home')
				} else if (index == 1) {
					this.$router.push('/about')
				} else if (index == 2) {
					this.$router.push('/product')
				} else if (index == 4) {
					this.$router.push('/contact')
				} else if (index == 5) {
					this.$router.push('/news')
				}
			},
			changeLanguage () {
				let url = '/en' + this.$route.fullPath
				// if (url == '/en/news') {
				// 	url = '/en/home'
				// }
				this.$router.push(url)
				// localStorage.setItem('languageSet', str)
				// window.location.reload()
			},
			search () {
				this.inputVal = ''
				this.inputActive = true
				setTimeout(() => {
					this.$refs.input.focus()
				}, 300)
			},
			close () {
				this.inputVal = ''
				this.inputActive = false
			}
		}
	}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>