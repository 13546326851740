const info={
  ZH: {  //中文翻译
    about: ['关于我们', '企业概况', '企业荣誉', '企业文化', '加入我们'],
    news: ['新闻中心', '企业动态', '最新活动'],
    support: ['服务支持', '产品防伪', '下载中心', '网点地图'],
    contact: ['联系我们', '全国服务热线:']
  },
  EN: {  //英文翻译
    about: ['about us', 'profile', 'honor', 'culture', 'join us'],
    news: ['news', 'dynamic', 'activity'],
    support: ['support', 'anti-fake', 'download', 'map'],
    contact: ['contact us', 'national service hotline:']
  }
}
export default info