import Mask from './Mask.vue'
import HeaderArea from './headerArea/head-area.vue'
import FooterArea from './footerArea/footer-area.vue'
import HeaderAreaEn from './headerArea/head-area-en.vue'
import FooterAreaEn from './footerArea/footer-area-en.vue'
import Backtop from './backtop.vue'

export default {
  install(Vue){
    //注册全局组件
    Vue.component('mask-view', Mask)
    Vue.component('head-area', HeaderArea)
    Vue.component('footer-area', FooterArea)
    Vue.component('head-area-en', HeaderAreaEn)
    Vue.component('footer-area-en', FooterAreaEn)
    Vue.component('backtop-view', Backtop)
  }
}