const info={
  ZH: {  //中文翻译
    brandList: {
      brand1: {
        title: '品牌文化',
        arr: [
          { title: '品牌理念', info: '有趣、探索、未来' },
          { title: '品牌价值观', info: '以用户价值为中心、创造有趣的生活' },
          { title: '品牌口号', info: '情不自禁 趣无止境' },
          { title: '品牌客群', info: '对爱向往有趣，自由、平等的每个人' }
        ]
      },
      brand2: {
        title: '品牌理念',
        arr: [
          { title: '严苛的检验标准', info: '有趣、探索、未来' },
          { title: '有趣实用主义', info: '我们销售的不是情趣用品，我们只是幸福缔造者' },
          { title: '以人为本', info: 'AAV自成立以来，始终坚持以用户价值为中心，以技术驱动创新' }
        ]
      }
    }
  },
  EN: {  //英文翻译
    brandList: {
      brand1: {
        title: '品牌文化',
        arr: [
          { title: '品牌理念', info: '有趣、探索、未来' },
          { title: '品牌价值观', info: '以用户价值为中心、创造有趣的生活' },
          { title: '品牌口号', info: '情不自禁 趣无止境' },
          { title: '品牌客群', info: '对爱向往有趣，自由、平等的每个人' }
        ]
      },
      brand2: {
        title: '品牌理念',
        arr: [
          { title: '严苛的检验标准', info: '有趣、探索、未来' },
          { title: '有趣实用主义', info: '我们销售的不是情趣用品，我们只是幸福缔造者' },
          { title: '以人为本', info: 'AAV自成立以来，始终坚持以用户价值为中心，以技术驱动创新' }
        ]
      }
    }
  }
}
export default info